import { Component } from '@angular/core';
import { environment } from "../../e2e/src/taining.config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bayer Training Tracker';
  
}
