// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  headerConfig: {
    contentType: "Content-Type",
    format: "application/json",
    apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA="
  },
  users: {
    apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA=",
    apiUrl: "https://ttr-bayer.com/fort/api/users/", //"https://uat.ttr-bayer.com/uat-fort/api/users/",
    endPoints: {
      update: "update",
      signup:"signup",
      // "login":"login",
      // "create":"create",
      // "update":"update",
      // "details":"userdetails",
      // "users":"users",
      // "userListing":"users",
      pendingUsers:"userrequests",
      forgotpassword: "forgot-password",
      resetpassword: "reset-password"
    }
  },
  admin: {
    apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA=",
    apiUrl: "https://ttr-bayer.com/kpis/",
    endPoints: {
      create: "create",
      update: "update",
      details: "details"
    }
  },
  training: {
    apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA=",
    apiUrl: "https://ttr-bayer.com/trainings/",
    endPoints: {
      training: "training",
      complete:"complete",
      update:"update",
      bestpractices:"bestpractices",
      notifications: "notifications"
    }
  },
  // notification: {
  //   apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA=",
  //   apiUrl: "https://uat.ttr-bayer.com/uat-trainings/",
  //   endPoints: {
  //     notifications: "notifications"
  //   }
  // },
  kpi:{
    apiKey: "MQkPOhNqBzs2xyYK/X+FO3y9rbK1oKIXfEJMmwVH6rA=",
    apiUrl: "https://ttr-bayer.com/kpis/",
    endPoints: {
      data: "kpis",
      countries:"kpis/countries",
      allkpi:"kpis/masterdata"
    }
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
