import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { Users } from "../models/users.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  private currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  protected httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      apiKey: environment.users.apiKey
    })
  };

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Users>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Users {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string): Observable<Users> {
    const userLoginData = {
      user: {
        email: username,
        password: password
      }
    };
    return this.http
      .post<Users>(
        environment.users.apiUrl + "login",
        userLoginData,
        this.httpOptions
      )
      .pipe(
        map(user => {
          // store user details and jwt currentUserValuetoken in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }

  userLoggedData() {
    let userToken = this.currentUserValue.token.split(".")[1];

    let decodeUserData = JSON.parse(window.atob(userToken));
    //console.log(decodeUserData);
    return decodeUserData.data;
  }
  
}
