import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";

import { LoginService } from "../../modules/users/services/login.service";
import { environment } from "src/environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private LoginService: LoginService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    var splitUrl = request.url.split("/");
    var requestHeaders = new HttpHeaders({
      "Content-Type": environment.headerConfig.format,
      apikey: environment.headerConfig.apiKey
    });
    
    if (splitUrl.includes("upload")) {
      requestHeaders = new HttpHeaders({
        apikey: environment.headerConfig.apiKey
      });
    }

    // add authorization header with jwt token if available
    let currentUser = this.LoginService.currentUserValue;
    if (currentUser && currentUser.token) {
      requestHeaders = requestHeaders.set(
        "Authorization",
        `Token ${currentUser.token}`
      );
      request = request.clone({ headers: requestHeaders });
    }
    return next.handle(request);
  }
}
