import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalComponent } from './shared/global/global.component';

import { JwtInterceptor } from './core/auth-guard/jwt.interceptor';
import { ErrorInterceptor } from './core/auth-guard/error.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
//import { AutoRefreshComponent } from './shared/auto-refresh/auto-refresh.component';
//import { FiltersComponent } from './shared/render-filters/filters/filters.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { ToastrModule } from 'ngx-toastr';
//DatePicker
import * as jquery from "jquery";
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
  declarations: [
    AppComponent,
    GlobalComponent,
    
    //AutoRefreshComponent,
    //FiltersComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,    
    AppRoutingModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    // DatePicker
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule
    

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports:[ 
       
  ],  
  providers: [
    NgbActiveModal,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
    { provide: LocationStrategy, useClass: HashLocationStrategy },    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
